
import { Stack, Switch, Typography } from '@mui/material'
import React from 'react'

export default function CustomSwitch({ label, checked, onChange, name }) {
    return (
        <Stack direction="row" spacing={1} sx={{ mt: 1 }} alignItems="center">
            <Typography style={{ fontFamily: 'Overpass', fontSize: 'small', fontWeight: "600", color: 'grey' }}>{label}</Typography>
            <Switch
                checked={checked}
                name={name}
                onChange={onChange}
            />
        </Stack>
    )
}
