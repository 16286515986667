import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router'

import "./App.scss";

import { signIn } from './redux/consts'
import Home from "./pages/home/Home";
import DigitalLearningMain from "./pages/DigitalLearning/digitalLearningMain/DigitalLearningMain";
import DigitalLearningPY1Create from "./pages/DigitalLearning/digitalLearningCreate/DigitalLearningPY1Create";
import DigitalLearningPY2Create from "./pages/DigitalLearning/digitalLearningCreate/DigitalLearningPY2Create";
import DigitalLearningPY1Update from "./pages/DigitalLearning/digitalLearningUpdate/DigitalLearningPY1Update";
import DigitalLearningPY2Update from "./pages/DigitalLearning/digitalLearningUpdate/DigitalLearningPY2Update";
import LectureCapsuleMain from "./pages/LectureCapsule/lectureCapsuleMain/LectureCapsuleMain";
import LectureCapsulePY1Create from "./pages/LectureCapsule/lectureCapsuleCreate/LectureCapsulePY1Create";
import LectureCapsulePY2Create from "./pages/LectureCapsule/lectureCapsuleCreate/LectureCapsulePY2Create";
import LectureCapsulePY1Update from "./pages/LectureCapsule/lectureCapsuleUpdate/LectureCapsulePY1Update";
import LectureCapsulePY2Update from "./pages/LectureCapsule/lectureCapsuleUpdate/LectureCapsulePY2Update";
import Login from "./pages/Login/Login";
import MasterData from "./pages/MasterData/MasterData";
import ManageUsersMain from "./pages/ManageUsers/ManageUsersMain/ManageUsersMain";
import ManageUsersCreate from "./pages/ManageUsers/ManageUsersCreate/ManageUsersCreate";
import ManageUsersUpdate from "./pages/ManageUsers/ManageUsersUpdate/ManageUsersUpdate";
import PrivateRoute from "./services/private/privateRoute";
import { getTokenVerified } from "./services/axios/authAPI/auth";
import { useEffect } from "react";

function App() {

  const dispatch = useDispatch();
  const history = useHistory();
  const u = localStorage.getItem('medace_user') || null
  console.log("App called")

  if (u) {
    dispatch({
      type: signIn,
      payLoad: {
        initial: {
          isAuth: true
        },
      },
    });
  }

  useEffect(() => {
    const u = localStorage.getItem('medace_user') || null
    if (u) {
      getTokenVerified({ token: JSON.parse(localStorage.getItem("medace_user")).token }).then(res => {
        if (res.code === "token_not_valid") {
          localStorage.clear()
          dispatch({
            type: signIn,
            payLoad: {
              initial: {
                isAuth: false
              },
            },
          });
          history.push('/login')
        }
      })
    }
  })

  let isAuth = useSelector((s) => s.signIn.initial.isAuth);


  return (
    <Router>
      <Switch>

        <Route exact path="/login">
          <Login />
        </Route>

        <PrivateRoute exact path="/" component={Home} isAllowed={isAuth} redirect="/login" />

        {/* DIGITAL LEARNING  */}

        <PrivateRoute exact path="/digital-learning/:year" component={DigitalLearningMain} isAllowed={isAuth} redirect="/login" />
        <PrivateRoute exact path="/new-digital-learning/1" component={DigitalLearningPY1Create} isAllowed={isAuth} redirect="/login" />
        <PrivateRoute exact path="/new-digital-learning/2" component={DigitalLearningPY2Create} isAllowed={isAuth} redirect="/login" />
        <PrivateRoute exact path="/digital-learning-update/1/:digitalLearningId" component={DigitalLearningPY1Update} isAllowed={isAuth} redirect="/login" />
        <PrivateRoute exact path="/digital-learning-update/2/:digitalLearningId" component={DigitalLearningPY2Update} isAllowed={isAuth} redirect="/login" />


        {/* LECTURE CAPSULE  */}

        <PrivateRoute exact path="/lecture-capsule/:year" component={LectureCapsuleMain} isAllowed={isAuth} redirect="/login" />
        <PrivateRoute exact path="/new-lecture-capsule/1" component={LectureCapsulePY1Create} isAllowed={isAuth} redirect="/login" />
        <PrivateRoute exact path="/new-lecture-capsule/2" component={LectureCapsulePY2Create} isAllowed={isAuth} redirect="/login" />
        <PrivateRoute exact path="/lecture-capsule-update/1/:lectureCapsuleId" component={LectureCapsulePY1Update} isAllowed={isAuth} redirect="/login" />
        <PrivateRoute exact path="/lecture-capsule-update/2/:lectureCapsuleId" component={LectureCapsulePY2Update} isAllowed={isAuth} redirect="/login" />


        {/* MASTER DATA  */}

        <PrivateRoute exact path="/master" component={MasterData} isAllowed={isAuth} redirect="/login" />
        <PrivateRoute exact path="/manage-users" component={ManageUsersMain} isAllowed={isAuth} redirect="/login" />
        <PrivateRoute exact path="/new-user" component={ManageUsersCreate} isAllowed={isAuth} redirect="/login" />
        <PrivateRoute exact path="/user-update/:userId" component={ManageUsersUpdate} isAllowed={isAuth} redirect="/login" />


      </Switch>
    </Router>
  );
}

export default App;
