import axios from '../index'
import { headers } from '../headers'


export const getAllPY2LectureCapsules = () => {
    return axios.get('/year_2/', {
        params: {
            course_type: 2,
            page_size: 5000
        }
    })
        .then(res => res.data)
        .catch(e => e.response.data)
}

export const getOnePY2LectureCapsule = (id) => {
    return axios.get(`/year_2/${id}/`, {
        params: {
            course_type: 2
        }
    })
        .then(res => res.data)
        .catch(e => e.response.data)
}

export const updatePY2LectureCapsule = (id, data) => {
    return axios.put(`/year_2/${id}/`, { ...data }, { headers: headers() })
        .then(res => res.data)
        .catch(e => e.response.data)
}

export const createPY2LectureCapsule = (data) => {
    return axios.post(`/year_2/`, { ...data, course_type: 2 }, {
        params: {
            course_type: 2,
        },
        headers: headers()
    })
        .then(res => res.data)
        .catch(e => e.response.data)
}

export const deletePY2LectureCapsule = (id) => {
    return axios.delete(`/year_2/${id}`, {
        headers: headers()
    })
        .then(res => res)
        .catch(e => e.response.data)
}