import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Card, Button, TextField } from '@mui/material';
import UpdateIcon from '@mui/icons-material/Update';
import "./DigitalLearningUpdate.scss";
import Sidebar from "../../../components/sidebar/Sidebar";
import Topbar from "../../../components/topbar/Topbar";

import { getAllOrgansWithDuplicates, getAllSubjecOnetWithDuplicates, getOneOrgan, getOneSubjectOne } from '../../../services/axios/masterDataAPI/masterDataAPI'
import { getOnePY1DigitalLearning, updatePY1DigitalLearning } from '../../../services/axios/digitalLearningAPI/digitalLearningPY1CRUD';
import DropDown from '../../../components/DropDown/DropDown';

export default function DigitalLearningUpdate() {

  const initial = {
    organ: "",
    display_name: "",
    competency_number: [],
    keywords: [],
    links: "",
    subject: "",
  }

  let { year, digitalLearningId } = useParams();
  const [digitalLearning, setDigitalLearning] = useState(initial)
  const [data, setData] = useState()
  const [allSubjects, setAllSubjects] = useState()
  const [subjectName, setSubjectName] = useState()
  const [organName, setOrganName] = useState()
  const [allOrgans, setAllOrgans] = useState()

  let Toast = useSelector((s) => s.toast);

  const fetchOneSubjectName = async (id) => {
    getOneSubjectOne(id).then(res => {
      setSubjectName(res.data.name)
    })
  }

  const fetchAllSubjects = () => {
    getAllSubjecOnetWithDuplicates().then(res => {
      if (res.success) {
        let subjectFilterArray = res.data.results.filter(item => {
          return item.organ.id === digitalLearning.organ
        })
        setAllSubjects(subjectFilterArray)
      }
    })
  }

  const fetchOneOrganName = async (id) => {
    getOneOrgan(id).then(res => {
      setOrganName(res.data.name)
    })
  }


  const fetchAllOrgans = () => {
    getAllOrgansWithDuplicates().then(res => {
      if (res.success) {
        let organFilterArray = res.data.results.filter(item => {
          return item.year.id === 1
        })
        setAllOrgans(organFilterArray)
      }
    })
  }

  const handleChange = (e) => {
    setDigitalLearning({
      ...digitalLearning,
      [e.target.name]: e.target.value
    })
  }

  const handleArrayInputChange = (e) => {
    setDigitalLearning({
      ...digitalLearning,
      [e.target.name]: e.target.value.split(',')
    })
  }

  useEffect(() => {
    getOnePY1DigitalLearning(digitalLearningId).then(res => {
      if (res.success) {
        setDigitalLearning(res.data)
        setData(res.data)
        fetchOneSubjectName(res.data.subject)
        fetchOneOrganName(res.data.organ)
      }
    })
    fetchAllOrgans()
  }, [digitalLearningId])

  useEffect(() => {
    if (digitalLearning) {
      fetchAllSubjects()
    }
  }, [digitalLearning])

  const handleSubmit = () => {
    updatePY1DigitalLearning(digitalLearningId, { ...digitalLearning }).then(res => {
      if (res.success) {
        fetchOneSubjectName(digitalLearning.subject)
        fetchOneOrganName(digitalLearning.organ)
        setData(digitalLearning)
        Toast.fire({
          icon: 'success',
          title: `Digital Learning update successful!`
        })
      } else {
        Toast.fire({
          icon: 'error',
          title: `Something went wrong!`
        })
      }
    })
  }




  return (
    <>
      <Topbar />
      <div className="_digital_learning_update_container">
        <Sidebar />
        <div style={{ margin: 20, width: '90%' }}>
          <h3 className="userTitle">Edit PY1 Digital Learning</h3>

          <div className="_digital_learning_container">

            <Card sx={{ p: 2, flex: 2 }} className="_digital_learning_info" >
              <span className="_sub_title">Digital Learning Details</span>
              <div className="_digital_learning_info_container">
                <span className="_digital_learning_info_title">{data && organName && organName}</span>
              </div>
              <div className="_digital_learning_info_container">
                <span className="_digital_learning_info_title">{data && subjectName && subjectName}</span>
              </div>

              <div className="_digital_learning_info_container">
                <span className="_digital_learning_info_title">{data && data.display_name}</span>
              </div>
              <div className="_digital_learning_info_container">
                <span className="_digital_learning_info_title">{data && data.competency_number.join()}</span>
              </div>
              <div className="_digital_learning_info_container">
                <span className="_digital_learning_info_title">{data && data.keywords.join()}</span>
              </div>
              <div className="_digital_learning_info_container">
                <span className="_digital_learning_info_title">{data && data.links}</span>
              </div>
            </Card>
            <Card sx={{ p: 4, flex: 2 }} className="_digital_learning_edit" >
              <h3>Edit</h3>
              <form>
                <div style={{ display: 'flex', flexDirection: 'column', }}>
                  <DropDown
                    name="organ"
                    label="Organ"
                    menu={allOrgans && allOrgans}
                    value={digitalLearning && digitalLearning.organ}
                    onChange={handleChange}
                  />
                  <DropDown
                    name="subject"
                    label="Subject"
                    menu={allSubjects && allSubjects}
                    value={digitalLearning && digitalLearning.subject}
                    onChange={handleChange}

                  />

                  <TextField
                    name="display_name"
                    label="Display file name"
                    variant="standard"
                    onChange={handleChange}
                    value={digitalLearning && digitalLearning.display_name}

                  />

                  <TextField
                    name="competency_number"
                    label="Competency Number"
                    variant="standard"
                    onChange={handleArrayInputChange}
                    value={digitalLearning && digitalLearning.competency_number}

                  />
                  <TextField
                    name="keywords"
                    label="Keywords"
                    variant="standard"
                    onChange={handleArrayInputChange}
                    value={digitalLearning && digitalLearning.keywords}

                  />
                  <TextField
                    name="links"
                    label="Link"
                    variant="standard"
                    onChange={handleChange}
                    value={digitalLearning && digitalLearning.links}

                  />
                  <Button
                    onClick={handleSubmit}
                    sx={{ mt: 2, alignSelf: 'end' }}
                    variant="contained"
                    endIcon={<UpdateIcon />}
                  >
                    Update
                  </Button>
                </div>

              </form>
            </Card>
          </div>
        </div>

      </div>
    </>
  );
}
