import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { TextField, Card, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import './DigitalLearningCreate.scss'
import Sidebar from '../../../components/sidebar/Sidebar';
import Topbar from '../../../components/topbar/Topbar';
import { getAllOrgansWithDuplicates, getAllSubjecOnetWithDuplicates, getOneOrgan } from '../../../services/axios/masterDataAPI/masterDataAPI';
import { createPY1DigitalLearning } from '../../../services/axios/digitalLearningAPI/digitalLearningPY1CRUD';
import DropDown from '../../../components/DropDown/DropDown';
import { validatePY1InteractiveLearning } from './validateDigitalLearning';


export default function DigitalLearningCreate() {

    const initial = {
        year: 1,
        organ: "",
        display_name: "",
        keywords: [],
        competency_number: [],
        links: "",
        subject: "",
    }

    const [inputs, setInputs] = useState(initial)
    const [subjects, setSubjects] = useState([])
    const [organs, setOrgans] = useState([])

    let { year } = useParams()
    const history = useHistory();
    let Toast = useSelector((s) => s.toast);

    const fetchAllSubjects = () => {
        getAllSubjecOnetWithDuplicates().then(res => {
            if (res.success) {
                let subjectFilterArray = res.data.results.filter(item => {
                    return item.organ.id === inputs.organ
                })
                setSubjects(subjectFilterArray)
            }
        })
    }

    const fetchAllOrgans = () => {
        getAllOrgansWithDuplicates().then(res => {
            if (res.success) {
                let organFilterArray = res.data.results.filter(item => {
                    return item.year.id === 1
                })
                setOrgans(organFilterArray)
            }
        })
    }

    useEffect(() => {
        fetchAllOrgans()
    }, [])

    useEffect(() => {
        fetchAllSubjects()
    }, [inputs.organ])

    const handleChange = (e) => {
        setInputs({
            ...inputs,
            [e.target.name]: e.target.value
        })
    }

    const handleArrayInputChange = (e) => {
        setInputs({
            ...inputs,
            [e.target.name]: e.target.value.split(',')
        })
    }

    const handleAddDigitalLearning = () => {
        if (!validatePY1InteractiveLearning(inputs)) {
            createPY1DigitalLearning({ ...inputs }).then(res => {
                if (res.success) {
                    Toast.fire({
                        icon: 'success',
                        title: `Digital Learning created successfully!`
                    })
                    history.push('/digital-learning/1')
                } else {
                    Toast.fire({
                        icon: 'error',
                        title: `Something went wrong!`
                    })
                }
            })
        } else {
            Toast.fire({
                icon: 'error',
                title: `Please fill all the fields!`
            })
        }
    }

    return (
        <>
            <Topbar />
            <div className="_digital_learning_create_container">
                <Sidebar />
                <div style={{ margin: 20 }}>
                    <h3>Add New PY1 Digital Learning</h3>
                    <Card
                        sx={{
                            p: 3, mt: 2
                        }}
                        elevation={2}
                        className="_form_container"

                    >
                        <form>
                            <div style={{ display: 'flex', flexDirection: 'column', }}>
                                <DropDown
                                    name="organ"
                                    label="Organ"
                                    menu={organs}
                                    value={inputs.organ}
                                    onChange={handleChange}
                                />
                                <DropDown
                                    name="subject"
                                    label="Subject"
                                    menu={subjects}
                                    value={inputs.subject}
                                    onChange={handleChange}
                                />

                                <TextField
                                    name="display_name"
                                    label="Display file name"
                                    variant="standard"
                                    onChange={handleChange}
                                    value={inputs.display_name}
                                />
                                <TextField
                                    name="competency_number"
                                    label="Competency Number"
                                    variant="standard"
                                    onChange={handleArrayInputChange}
                                    value={inputs.competency_number}
                                />
                                <TextField
                                    name="keywords"
                                    label="Keywords"
                                    variant="standard"
                                    onChange={handleArrayInputChange}
                                    value={inputs.keywords}
                                />
                                <TextField
                                    name="links"
                                    label="Link"
                                    variant="standard"
                                    onChange={handleChange}
                                    value={inputs.links}
                                />
                                <Button
                                    onClick={handleAddDigitalLearning}
                                    sx={{ mt: 2, alignSelf: 'end' }}
                                    variant="contained"
                                    endIcon={<AddIcon />}
                                >
                                    Add
                                </Button>
                            </div>

                        </form>
                    </Card>
                </div>


            </div >
        </>
    )
}
