export const validatedUser = (inputs) => {
    if (inputs.email === "") {
        return true
    }
    if (inputs.password === "") {
        return true
    }
    if (inputs.first_name === "") {
        return true
    }
    if (inputs.last_name === "") {
        return true
    }

    return false

}