import React, { useState } from 'react'
import { Button, Card, TextField, } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';


import './ManageUsersCreate.scss'

import Sidebar from '../../../components/sidebar/Sidebar'
import Topbar from '../../../components/topbar/Topbar'
import CustomSwitch from '../../../components/CustomSwitch/CustomSwitch';
import { createNewUser } from '../../../services/axios/userAPI/userCRUD';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { validatedUser } from './validations';

export default function ManageUsersCreate() {


    const initial = {
        email: "",
        password: "",
        first_name: "",
        last_name: "",
        is_staff: false,
        is_active: true
    }

    const [inputs, setInputs] = useState(initial);
    const history = useHistory();
    let Toast = useSelector((s) => s.toast);

    const handleChange = (e) => {
        setInputs({
            ...inputs,
            [e.target.name]: e.target.value
        })
    }

    const handleSwitchChange = (e) => {
        setInputs({
            ...inputs,
            [e.target.name]: e.target.checked
        })
    }

    const handleUserAdd = () => {
        if (!validatedUser(inputs)) {
            createNewUser({ ...inputs }).then(res => {
                if (res.success) {
                    Toast.fire({
                        icon: 'success',
                        title: `Successfully created user!`
                    })
                    history.push('/manage-users')
                } else {
                    Toast.fire({
                        icon: 'error',
                        title: `Something went wrong!`
                    })
                }
            })
        } else {
            Toast.fire({
                icon: 'error',
                title: `Please fill all the fields!`
            })
        }

    }

    return (
        <>
            <Topbar />
            <div className="_manage_users_create_container">
                <Sidebar />

                <div style={{ margin: 20 }}>
                    <h3>Add new user</h3>
                    <Card
                        sx={{
                            p: 2, mt: 2
                        }}
                        elevation={2}
                        className="_form_container"

                    >
                        <form>
                            <div style={{ display: 'flex', flexDirection: 'column', }}>
                                <TextField
                                    name="email"
                                    type="email"
                                    label="Email"
                                    variant="standard"
                                    onChange={handleChange}
                                    value={inputs.email}
                                />
                                <TextField
                                    name="password"
                                    label="Password"
                                    variant="standard"
                                    onChange={handleChange}
                                    value={inputs.password}

                                />
                                <TextField
                                    name="first_name"
                                    label="First Name"
                                    variant="standard"
                                    onChange={handleChange}
                                    value={inputs.first_name}

                                />
                                <TextField
                                    name="last_name"
                                    label="Last Name"
                                    variant="standard"
                                    onChange={handleChange}
                                    value={inputs.last_name}
                                />

                                <CustomSwitch
                                    label="Staff"
                                    name="is_staff"
                                    checked={inputs.is_staff}
                                    onChange={handleSwitchChange}

                                />
                                <CustomSwitch
                                    label="Active"
                                    name="is_active"
                                    checked={inputs.is_active}
                                    onChange={handleSwitchChange}

                                />
                                <Button
                                    sx={{ mt: 2, alignSelf: 'end' }}
                                    variant="contained"
                                    endIcon={<AddIcon />}
                                    onClick={handleUserAdd}
                                >
                                    Add
                                </Button>
                            </div>

                        </form>
                    </Card>
                </div>


            </div >
        </>
    )
}
