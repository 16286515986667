import React from "react";
import { useHistory } from 'react-router'
import { useDispatch } from "react-redux";

import { signIn } from '../../redux/consts'
import "./topbar.scss";
import logo from '../../assets/logo.jpg'
import avatar from '../../assets/icons/avatar.png'
import Button from '@mui/material/Button';
import LockIcon from '@mui/icons-material/Lock';

export default function Topbar() {
  const history = useHistory()
  const dispatch = useDispatch();

  const handleLogout = () => {
    localStorage.clear()
    dispatch({
      type: signIn,
      payLoad: {
        initial: {
          isAuth: false
        },
      }
    })
    history.push('/login')
  }


  return (
    <div className="topbar">
      <div className="topbarWrapper">
        <div className="topLeft">
          <img src={logo} alt="logo" className="logo" />
        </div>
        <div className="topRight">
          {/* <div className="topbarIconContainer">
            <NotificationsNone />
            <span className="topIconBadge">2</span>
          </div>
          <div className="topbarIconContainer">
            <Language />
            <span className="topIconBadge">2</span>
          </div>
          <div className="topbarIconContainer">
            <Settings />
          </div> */}
          <Button
            variant="contained"
            className="_logout_button"
            endIcon={<LockIcon />}
            onClick={handleLogout}
          >
            Logout
          </Button>
          <img src={avatar} alt="avatar" className="topAvatar" />
        </div>
      </div>
    </div>
  );
}
