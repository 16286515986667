import React, { useState, useEffect } from 'react'
import { TextField, Card, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import './LectureCapsuleCreate.scss'
import Sidebar from '../../../components/sidebar/Sidebar';
import Topbar from '../../../components/topbar/Topbar';
import { createPY1LectureCapsule } from '../../../services/axios/lectureCapsulesAPI/lectureCapsulesPY1CRUD';
import { getAllSubjecOnetWithDuplicates, getAllOrgansWithDuplicates } from '../../../services/axios/masterDataAPI/masterDataAPI';
import { useHistory, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import DropDown from '../../../components/DropDown/DropDown';
import { validatePY1LectureCapsule } from './validations';

export default function LectureCapsuleCreate() {

    const initial = {
        year: 2,
        organ: "",
        display_name: "",
        keywords: [],
        competency_number: [],
        links: "",
        subject: "",
    }
    const [inputs, setInputs] = useState(initial)
    const [subjects, setSubjects] = useState([])
    const [organs, setOrgans] = useState([])

    let { year } = useParams()
    const history = useHistory();
    let Toast = useSelector((s) => s.toast);

    const fetchAllSubjects = () => {
        getAllSubjecOnetWithDuplicates().then(res => {
            if (res.success) {
                let subjectFilterArray = res.data.results.filter(item => {
                    return item.organ.id === inputs.organ
                })
                setSubjects(subjectFilterArray)
            }
        })
    }

    const fetchAllOrgans = () => {
        getAllOrgansWithDuplicates().then(res => {
            if (res.success) {
                let organFilterArray = res.data.results.filter(item => {
                    return item.year.id === 2
                })
                setOrgans(organFilterArray)
            }
        })
    }


    useEffect(() => {
        fetchAllOrgans()

    }, [])

    useEffect(() => {
        fetchAllSubjects()
    }, [inputs.organ])

    const handleChange = (e) => {
        setInputs({
            ...inputs,
            [e.target.name]: e.target.value
        })
    }

    const handleArrayInputChange = (e) => {
        setInputs({
            ...inputs,
            [e.target.name]: e.target.value.split(',')
        })
    }

    const handleAddLectureCapsule = () => {
        if (!validatePY1LectureCapsule(inputs)) {
            createPY1LectureCapsule({ ...inputs }).then(res => {
                if (res.success) {
                    Toast.fire({
                        icon: 'success',
                        title: `Lecture Capsule created successfully!`
                    })
                    history.push('/lecture-capsule/1')
                } else {
                    Toast.fire({
                        icon: 'error',
                        title: `Something went wrong!`
                    })
                }
            })
        } else {
            Toast.fire({
                icon: 'error',
                title: `Please fill all the fields!`
            })
        }

    }
    return (
        <>
            <Topbar />
            <div className="_lecture_capsule_create_container">
                <Sidebar />
                <div style={{ margin: 20 }}>
                    <h3>Add New PY1 Lecture Capsule</h3>
                    <Card
                        sx={{
                            p: 2, mt: 2
                        }}
                        elevation={2}
                        className="_form_container"

                    >
                        <form>
                            <div style={{ display: 'flex', flexDirection: 'column', }}>
                                <DropDown
                                    name="organ"
                                    label="Organ"
                                    menu={organs}
                                    onChange={handleChange}
                                    value={inputs.organ}
                                />
                                <DropDown
                                    name="subject"
                                    label="Subject"
                                    menu={subjects}
                                    value={inputs.subject}
                                    onChange={handleChange}

                                />

                                <TextField
                                    name="display_name"
                                    label="Display file name"
                                    variant="standard"
                                    onChange={handleChange}
                                    value={inputs.display_name}

                                />
                                <TextField
                                    name="competency_number"
                                    label="Competency Number"
                                    variant="standard"
                                    onChange={handleArrayInputChange}
                                    value={inputs.competency_number}

                                />
                                <TextField
                                    name="keywords"
                                    label="Keywords"
                                    variant="standard"
                                    onChange={handleArrayInputChange}
                                    value={inputs.keywords}

                                />
                                <TextField
                                    name="links"
                                    label="Link"
                                    variant="standard"
                                    onChange={handleChange}
                                    value={inputs.links}

                                />
                                <Button
                                    onClick={handleAddLectureCapsule}
                                    sx={{ mt: 2, alignSelf: 'end' }}
                                    variant="contained"
                                    endIcon={<AddIcon />}
                                >
                                    Add
                                </Button>
                            </div>

                        </form>
                    </Card>
                </div>


            </div >
        </>
    )
}
