import React, { useEffect } from 'react'
import { useHistory } from 'react-router'

export default function Home() {

  const history = useHistory();

  useEffect(() => {
    history.push('/lecture-capsule/1')
  }, [history])


  return (
    <div className="home">
      {/* <FeaturedInfo />
      <Chart data={userData} title="User Analytics" grid dataKey="Active User"/>
      <div className="homeWidgets">
        <WidgetSm/>
        <WidgetLg/>
      </div> */}
    </div>
  );
}
