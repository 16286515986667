import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router'
import { Button } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Link } from 'react-router-dom'

import './LectureCapsuleMain.scss'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Sidebar from '../../../components/sidebar/Sidebar';
import Topbar from '../../../components/topbar/Topbar';

import { deletePY1LectureCapsule, getAllPY1LectureCapsules } from '../../../services/axios/lectureCapsulesAPI/lectureCapsulesPY1CRUD';
import { deletePY2LectureCapsule, getAllPY2LectureCapsules } from '../../../services/axios/lectureCapsulesAPI/lectureCapsulesPY2CRUD';
import Swal from 'sweetalert2';
import { useSelector } from 'react-redux';
import { ChangeSubjectOneIdAndOrganIdToNameInResponse, ChangeSubjectTwoIdAndTopicIdToNameInResponse } from '../../../services/custom-helper-functions/helper-functions';


export default function LectureCapsuleMain() {

    const [data, setData] = useState({});
    const [gotResponse, setGotResponse] = useState(false)


    const history = useHistory()
    let { year } = useParams();
    let Toast = useSelector((s) => s.toast);

    const fetchAllPY1LectureCapsules = () => {

        getAllPY1LectureCapsules().then(async (res) => {
            setGotResponse(true)
            ChangeSubjectOneIdAndOrganIdToNameInResponse(res.data.results).then(newData => {
                setData(newData)
            })
        })
    }

    const fetchAllPY2LectureCapsules = () => {
        getAllPY2LectureCapsules().then(res => {
            setGotResponse(true)
            ChangeSubjectTwoIdAndTopicIdToNameInResponse(res.data.results).then(newData => {
                setData(newData)
            })
        })
    }

    useEffect(() => {

        if (year === '1') {
            fetchAllPY1LectureCapsules()
        }
        if (year === '2') {
            fetchAllPY2LectureCapsules()
        }
    }, [year])


    const handleDelete = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                if (year === '1') {
                    deletePY1LectureCapsule(id).then(res => {
                        console.log(res)
                        if (res.status === 204) {
                            Toast.fire({
                                icon: 'success',
                                title: `Lecture Capsule deleted successfully!`
                            })
                            fetchAllPY1LectureCapsules()
                        } else {
                            Toast.fire({
                                icon: 'error',
                                title: `Something went wrong!`
                            })
                        }
                    })
                }
                if (year === '2') {
                    deletePY2LectureCapsule(id).then(res => {
                        console.log(res)
                        if (res.status === 204) {
                            Toast.fire({
                                icon: 'success',
                                title: `Lecture Capsule deleted successfully!`
                            })
                            fetchAllPY2LectureCapsules()
                        } else {
                            Toast.fire({
                                icon: 'error',
                                title: `Something went wrong!`
                            })
                        }
                    })
                }
            }
        })
    };

    const column1 = [
        { field: "id", headerName: "ID", width: 90, },
        { field: "organ", headerName: "Organ System", width: 200, },
        { field: "subject", headerName: "Subject", width: 200 },
        { field: "display_name", headerName: "Display Name", width: 200 },
        {
            field: "competency_number",
            headerName: "Competency No.",
            width: 160,
        },
        {
            field: "keywords",
            headerName: "Keywords",
            width: 160,
        },
        {
            field: "links",
            headerName: "Links",
            width: 160,
        },
        {
            field: "action",
            headerName: "Action",
            width: 150,
            renderCell: (params) => {
                return (
                    <>
                        <Link to={"/lecture-capsule-update/" + year + "/" + params.row.id}>
                            <button className="dataListEdit">Edit</button>
                        </Link>
                        <DeleteOutlineIcon
                            className="dataListDelete"
                            onClick={() => handleDelete(params.row.id)}
                        />
                    </>
                );
            },
        },
    ];

    const column2 = [
        { field: "id", headerName: "ID", width: 90, },
        { field: "topic_name", headerName: "Topic Name", width: 200, },
        { field: "subject", headerName: "Subject", width: 200 },
        { field: "display_name", headerName: "Display Name", width: 200 },
        {
            field: "competency_number",
            headerName: "Competency No.",
            width: 160,
        },
        {
            field: "keywords",
            headerName: "Keywords",
            width: 160,
        },
        {
            field: "links",
            headerName: "Links",
            width: 160,
        },
        {
            field: "action",
            headerName: "Action",
            width: 150,
            renderCell: (params) => {
                return (
                    <>
                        <Link to={"/lecture-capsule-update/" + year + "/" + params.row.id}>
                            <button className="dataListEdit">Edit</button>
                        </Link>
                        <DeleteOutlineIcon
                            className="dataListDelete"
                            onClick={() => handleDelete(params.row.id)}
                        />
                    </>
                );
            },
        },
    ];

    return (
        <>
            <Topbar />
            <div className="_lecture_capsule_main_container" >
                <Sidebar />
                <div style={{ margin: 20, flex: 1 }}>
                    <h3>{year === '1' ? "PY1" : "PY2"} Lecture Capsules</h3>
                    <div style={{ height: 500, marginTop: 20, alignItems: 'center' }}>
                        <DataGrid
                            rows={data}
                            columns={year === '1' ? column1 : column2}
                            loading={!gotResponse}

                        />
                    </div>
                    <h3 style={{ marginTop: 20 }}>Add a lecture capsule</h3>
                    <Button
                        sx={{ mt: 1 }}
                        variant="contained"
                        onClick={() => {
                            history.push(`/new-lecture-capsule/${year}`)
                        }}
                    >Create</Button>
                </div>

            </div>
        </>
    )
}
