import axios from '../index'
import { headers } from '../headers'

// YEAR API's 
export const getAllYears = () => {
    return axios.get('/year/')
        .then(res => res.data)
        .catch(e => e.response.data)
}


// SUBJECT API's
export const getAllSubjecOnetWithDuplicates = () => {
    return axios.get('/allsubjectone/', {
        params: {
            page_size: 1000
        }
    })
        .then(res => res.data)
        .catch(e => e.response.data)
}
export const getAllSubjectTwoWithDuplicates = () => {
    return axios.get('/allsubjecttwo/', {
        params: {
            page_size: 1000
        }
    })
        .then(res => res.data)
        .catch(e => e.response.data)
}

export const getAllSubjectOnes = (organName) => {
    return axios.get('/subjectsone/', {
        params: {
            organ__name__icontains: organName,
            page_size: 1000
        }
    })
        .then(res => res.data)
        .catch(e => e.response.data)
}
export const getAllSubjectTwos = () => {
    return axios.get('/subjectstwo/', {
        params: {
            page_size: 1000
        }
    })
        .then(res => res.data)
        .catch(e => e.response.data)
}

export const getOneSubjectOne = (id) => {
    return axios.get(`/subjectsone/${id}/`)
        .then(res => res.data)
        .catch(e => e.response.data)
}

export const getOneSubjectTwo = (id) => {
    return axios.get(`/subjectstwo/${id}/`)
        .then(res => res.data)
        .catch(e => e.response.data)
}

export const createSubjectOne = (data) => {
    return axios.post(`/allsubjectone/`, { ...data }, { headers: headers() })
        .then(res => res.data)
        .catch(e => e.response.data)
}

export const createSubjectTwo = (data) => {
    return axios.post(`/allsubjecttwo/`, { ...data }, { headers: headers() })
        .then(res => res.data)
        .catch(e => e.response.data)
}


// ORGAN API's

export const getAllOrgansWithDuplicates = () => {
    return axios.get('/allorgans/', {
        params: {
            page_size: 1000
        }
    })
        .then(res => res.data)
        .catch(e => e.response.data)
}

export const getAllOrgans = () => {
    return axios.get('/organ/', {
        params: {
            page_size: 1000
        }
    })
        .then(res => res.data)
        .catch(e => e.response.data)
}

export const getOneOrgan = (id) => {
    return axios.get(`/organ/${id}/`)
        .then(res => res.data)
        .catch(e => e.response.data)
}

export const createOrgan = (data) => {
    return axios.post(`/allorgans/`, { ...data }, { headers: headers() })
        .then(res => res.data)
        .catch(e => e.response.data)
}

// TOPIC API's 

export const getAllTopicsWithDuplicates = () => {
    return axios.get('/alltopics/', {
        params: {
            page_size: 1000
        }
    })
        .then(res => res.data)
        .catch(e => e.response.data)
}

export const getAllTopics = (subjectName) => {
    return axios.get('/topic/', {
        params: {
            subjects__name__icontains: subjectName,
            page_size: 1000
        }
    })
        .then(res => res.data)
        .catch(e => e.response.data)
}

export const getOneTopic = (id) => {
    return axios.get(`/alltopics/${id}/`)
        .then(res => res.data)
        .catch(e => e.response.data)
}

export const createTopic = (data) => {
    return axios.post(`/alltopics/`, { ...data }, { headers: headers() })
        .then(res => res.data)
        .catch(e => e.response.data)
}

//DELETE apis

export const deleteOrgan = ({ id }) => {
    return axios.delete(`/allorgans/${id}`, { headers: headers() })
        .then(res => res)
        .catch(e => e.response.data)
}

export const deleteSubjectOne = ({ id }) => {
    return axios.delete(`/allsubjectone/${id}`, { headers: headers() })
        .then(res => res)
        .catch(e => e.response.data)
}

export const deleteSubjectTwo = ({ id }) => {
    return axios.delete(`/allsubjecttwo/${id}`, { headers: headers() })
        .then(res => res)
        .catch(e => e.response.data)
}

export const deleteTopic = ({ id }) => {
    return axios.delete(`/alltopics/${id}`, { headers: headers() })
        .then(res => res)
        .catch(e => e.response.data)
}


// export const getAllQuestionTypes = () => {
//     return axios.get('/question_types/')
//         .then(res => res.data)
//         .catch(e => e.response.data)
// }

// export const getAllAssetTypes = () => {
//     return axios.get('/asset_types/')
//         .then(res => res.data)
//         .catch(e => e.response.data)
// }

//get-one


// export const getOneQuestionType = (id) => {
//     return axios.get(`/question_types/${id}/`)
//         .then(res => res.data)
//         .catch(e => e.response.data)
// }

// export const getOneAssetType = (id) => {
//     return axios.get(`/asset_types/${id}/`)
//         .then(res => res.data)
//         .catch(e => e.response.data)
// }


//POST API's


// export const createQuestionType = (data) => {
//     return axios.post(`/question_types/`, { ...data }, { headers: headers() })
//         .then(res => res.data)
//         .catch(e => e.response.data)
// }

// export const createAssetType = (data) => {
//     return axios.post(`/asset_types/`, { ...data }, { headers: headers() })
//         .then(res => res.data)
//         .catch(e => e.response.data)
// }

