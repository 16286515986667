import axios from '../index'
import { headers } from '../headers'

export const getAllPY1LectureCapsules = () => {
    return axios.get('/year_1/', {
        params: {
            course_type: 2,
            page_size: 5000
        }
    })
        .then(res => res.data)
        .catch(e => e.response.data)
}

export const getOnePY1LectureCapsule = (id) => {
    return axios.get(`/year_1/${id}/`, {
        params: {
            course_type: 2
        }
    })
        .then(res => res.data)
        .catch(e => e.response.data)
}

export const updatePY1LectureCapsule = (id, data) => {
    return axios.put(`/year_1/${id}/`, { ...data }, { headers: headers() })
        .then(res => res.data)
        .catch(e => e.response.data)
}

export const createPY1LectureCapsule = (data) => {
    return axios.post(`/year_1/`, { ...data, course_type: 2 }, {
        headers: headers()
    })
        .then(res => res.data)
        .catch(e => e.response.data)
}

export const deletePY1LectureCapsule = (id) => {
    return axios.delete(`/year_1/${id}`, {
        headers: headers()
    })
        .then(res => res)
        .catch(e => e.response.data)
}