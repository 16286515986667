import {
    getAllOrgansWithDuplicates,
    getAllTopicsWithDuplicates,
    getAllSubjecOnetWithDuplicates,
    getAllSubjectTwoWithDuplicates,
    getOneOrgan,
    getOneSubjectOne,
    getOneSubjectTwo,
    getOneTopic
} from "../axios/masterDataAPI/masterDataAPI"

const getSubjectOneName = (id) => {
    return getOneSubjectOne(id).then((res) => {
        if (res.success) {
            return res.data.name
        }
    })
}

const getSubjectTwoName = (id) => {
    return getOneSubjectTwo(id).then((res) => {
        if (res.success) {
            return res.data.name
        }
    })
}

const getOrganName = (id) => {
    return getOneOrgan(id).then((res) => {
        if (res.success) {
            return res.data.name

        }
    })
}

const getTopicName = (id) => {
    return getOneTopic(id).then((res) => {
        if (res.success) {
            return res.data.name
        }
    })
}


const ChangeSubjectOneIdToNameInResponse = (arr) => {

    arr.forEach((item, index) => {
        getSubjectOneName(item.subject).then(data => {
            item.subject = data
        })
    })

    return Promise.all(arr)
}

const ChangeSubjectTwoIdToNameInResponse = (arr) => {

    arr.forEach((item, index) => {
        getSubjectTwoName(item.subject).then(data => {
            item.subject = data
        })
    })

    return Promise.all(arr)
}

const ChangeOrganIdToNameInResponse = (arr) => {

    arr.forEach((item, index) => {
        getOrganName(item.organ).then(data => {
            item.organ = data
        })
    })

    return Promise.all(arr)
}

const ChangeSubjectOneIdAndOrganIdToNameInResponse = (arr) => {

    let subjects = []
    let organs = []
    getAllSubjecOnetWithDuplicates().then(res => {
        subjects = res.data.results
        arr.forEach((item, index) => {

            subjects.map(subject => {
                if (subject.id === item.subject) {
                    item.subject = subject.name
                }
            })

        })
    })

    getAllOrgansWithDuplicates().then(res => {
        organs = res.data.results
        arr.forEach((item, index) => {

            organs.map(organ => {
                if (organ.id === item.organ) {
                    item.organ = organ.name
                }
            })

        })
    })



    return Promise.all(arr)
}

const ChangeSubjectTwoIdAndTopicIdToNameInResponse = (arr) => {

    let subjects = []
    let topics = []

    getAllSubjectTwoWithDuplicates().then(res => {
        subjects = res.data.results
        arr.forEach((item, index) => {

            subjects.map(subject => {
                if (subject.id === item.subject) {
                    item.subject = subject.name
                }
            })

        })
    })

    getAllTopicsWithDuplicates().then(res => {
        topics = res.data.results
        arr.forEach((item, index) => {

            topics.map(topic => {
                if (topic.id === item.topic_name) {
                    item.topic_name = topic.name
                }
            })

        })
    })

    return Promise.all(arr)
}

export {
    ChangeSubjectOneIdToNameInResponse,
    ChangeSubjectTwoIdToNameInResponse,
    ChangeOrganIdToNameInResponse,
    ChangeSubjectOneIdAndOrganIdToNameInResponse,
    ChangeSubjectTwoIdAndTopicIdToNameInResponse
}