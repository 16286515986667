import React, { useState, useEffect } from 'react'
import { Button } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import { useHistory } from 'react-router'
import { Link } from 'react-router-dom';

import './ManageUsersMain.scss'
import { deleteUser, getAllUsers } from '../../../services/axios/userAPI/userCRUD'
import Sidebar from '../../../components/sidebar/Sidebar'
import Topbar from '../../../components/topbar/Topbar'
import Swal from 'sweetalert2';
import { useSelector } from 'react-redux';

const ManageUsersMain = () => {


    const [data, setData] = useState([]);
    const [gotResponse, setGotResponse] = useState(false)

    const history = useHistory()
    let Toast = useSelector((s) => s.toast);

    useEffect(() => {
        fetchAllUsers()
    }, [])

    const fetchAllUsers = () => {
        getAllUsers().then(res => {
            setGotResponse(true)
            setData(res.data.results)
        })
    }

    const handleDelete = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                deleteUser(id).then(res => {
                    console.log(res)
                    if (res.status === 204) {
                        Toast.fire({
                            icon: 'success',
                            title: `User deleted successfully!`
                        })
                        fetchAllUsers()
                    } else {
                        Toast.fire({
                            icon: 'error',
                            title: `Something went wrong!`
                        })
                    }
                })
            }
        })
    };

    const columns = [
        { field: "id", headerName: "ID", width: 200, },
        {
            field: "first_name",
            headerName: "Name",
            width: 100,
        },
        {
            field: "email",
            headerName: "Email",
            width: 200,
        },
        {
            field: "is_staff",
            headerName: "Is Staff",
            width: 100,
        },
        {
            field: "is_active",
            headerName: "Is Active",
            width: 100,
        },
        {
            field: "action",
            headerName: "Action",
            width: 150,
            renderCell: (params) => {
                return (
                    <>
                        <Link to={"/user-update/" + params.row.id}>
                            <button className="dataListEdit">Edit</button>
                        </Link>
                        <DeleteOutlineIcon
                            className="dataListDelete"
                            onClick={() => handleDelete(params.row.id)}
                        />
                    </>
                );
            },
        },
    ];


    return (
        <>
            <Topbar />
            <div className="_manage_users_main_container">
                <Sidebar />

                <div style={{ margin: '0px 20px', flex: 1 }}>
                    <h3>Available Users</h3>
                    <div style={{ height: 500, marginTop: 20, alignItems: 'center' }}>
                        <DataGrid
                            rows={data}
                            columns={columns}
                            loading={!gotResponse}
                        />
                    </div>
                    <h3 style={{ marginTop: 20 }}>Add a user</h3>
                    <Button
                        sx={{ mt: 1 }}
                        variant="contained"
                        onClick={() => {
                            history.push('/new-user')
                        }}
                    >Create</Button>

                </div>
            </div>
        </>
    )
}

export default ManageUsersMain;
