import React, { useState, useEffect } from 'react'
import { Button, Card, CircularProgress, TextField } from '@mui/material'
import UpdateIcon from '@mui/icons-material/Update';
import { useSelector } from 'react-redux';


import './ManageUsersUpdate.scss'
import Sidebar from '../../../components/sidebar/Sidebar'
import Topbar from '../../../components/topbar/Topbar'
import { useParams } from 'react-router';
import { getOneUser, updateUser } from '../../../services/axios/userAPI/userCRUD';
import CustomSwitch from '../../../components/CustomSwitch/CustomSwitch';

export default function ManageUsersUpdate() {

    const initial = {
        email: "",
        password: "",
        first_name: "",
        last_name: "",
        is_staff: false,
        is_active: false,
    }
    let { userId } = useParams();

    const [user, setUser] = useState(initial)
    const [data, setData] = useState()
    let Toast = useSelector((s) => s.toast);

    useEffect(() => {
        getOneUser(userId).then(res => {
            if (res.success) {
                setUser(res.data)
                setData(res.data)
            } else {
                Toast.fire({
                    icon: 'error',
                    title: 'Something went wrong!'
                })
            }
        })
    }, [Toast, userId])

    const handleTextChange = (e) => {
        setUser({
            ...user,
            [e.target.name]: e.target.value
        });
    }

    const handleSwitchChange = (e) => {
        setUser({
            ...user,
            [e.target.name]: e.target.checked
        })
    }

    const handleSubmit = () => {
        updateUser(userId, user).then(res => {
            if (res.success) {
                setData(user)
                Toast.fire({
                    icon: 'success',
                    title: `User Details update successul!`
                })
            } else {
                Toast.fire({
                    icon: 'error',
                    title: `Something went wrong!`
                })
            }
        })
    }

    return (
        <>
            <Topbar />
            <div className="_manage_users_update_container">
                <Sidebar />
                <div style={{ margin: 20, width: '90%' }}>
                    <h3 className="userTitle">Edit User Details</h3>

                    <div className="_manage_users_container">

                        <Card sx={{ p: 2, flex: 2 }} className="_manage_users_info" >
                            <span className="_sub_title">User Details</span>
                            {
                                user ?
                                    <>
                                        <div className="_manage_users_info_container">
                                            <span className="_manage_users_info_title">{data && data.email}</span>
                                        </div>
                                        <div className="_manage_users_info_container">
                                            <span className="_manage_users_info_title">{data && data.first_name + " " + data.last_name}</span>
                                        </div>
                                        <div className="_manage_users_info_container">
                                            <span className="_manage_users_info_title">{data && data.is_staff ? 'Staff' : ''}</span>
                                        </div>
                                        <div className="_manage_users_info_container">
                                            <span className="_manage_users_info_title">{data && data.is_active ? 'Active' : ''}</span>
                                        </div>
                                    </>
                                    :
                                    <div style={{ marginTop: '20px' }}>
                                        <CircularProgress />
                                    </div>
                            }

                        </Card>
                        <Card sx={{ p: 4, flex: 2 }} className="_manage_users_edit" >
                            <h3>Edit</h3>
                            <form>
                                <div style={{ display: 'flex', flexDirection: 'column', }}>
                                    <TextField
                                        name="email"
                                        label="Email"
                                        variant="standard"
                                        value={user && user.email}
                                        onChange={handleTextChange}
                                    />
                                    <TextField
                                        name="first_name"
                                        label="First Name"
                                        variant="standard"
                                        value={user && user.first_name}
                                        onChange={handleTextChange}


                                    />
                                    <TextField
                                        name="last_name"
                                        label="Last Name"
                                        variant="standard"
                                        value={user && user.last_name}
                                        onChange={handleTextChange}

                                    />
                                    <CustomSwitch
                                        label="Staff"
                                        name="is_staff"
                                        checked={user && user.is_staff}
                                        onChange={handleSwitchChange}

                                    />
                                    <CustomSwitch
                                        label="Active"
                                        name="is_active"
                                        checked={user && user.is_active}
                                        onChange={handleSwitchChange}

                                    />
                                    <Button
                                        onClick={handleSubmit}
                                        sx={{ mt: 2, alignSelf: 'end' }}
                                        variant="contained"
                                        endIcon={<UpdateIcon />}
                                    >
                                        Update
                                    </Button>
                                </div>

                            </form>
                        </Card>
                    </div>
                </div>

            </div>
        </>
    )
}
