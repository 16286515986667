import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { Card, CardContent, Button, IconButton } from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import UploadIcon from '@mui/icons-material/Upload';
import Swal from 'sweetalert2';

import './MasterData.scss'
import DropDown from '../../components/DropDown/DropDown';
import YearDropDown from '../../components/DropDown/YearDropDown'
import Sidebar from '../../components/sidebar/Sidebar'
import Topbar from '../../components/topbar/Topbar'
import TextFieldWithIcon from '../../components/TextFieldWithIcon/TextFieldWithIcon';
import { createOrgan, createSubjectOne, createSubjectTwo, deleteOrgan, deleteTopic, deleteSubjectOne, deleteSubjectTwo, createTopic, getAllOrgansWithDuplicates, getAllSubjecOnetWithDuplicates, getAllSubjectTwoWithDuplicates, getAllTopicsWithDuplicates, getAllYears } from '../../services/axios/masterDataAPI/masterDataAPI';

export default function MasterData() {
    const [addData, setAddData] = useState()
    const [deleteData, setDeleteData] = useState()
    const [yearInput, setYearInput] = useState('')

    const [yearOne, setYearOne] = useState()
    const [yearTwo, setyearTwo] = useState()

    const [subjectOne, setSubjectOne] = useState()
    const [subjectTwo, setSubjectTwo] = useState()

    const [subjectInput, setSubjectInput] = useState()

    const [topics, setTopics] = useState()
    const [topicInput, setTopicInput] = useState('')


    const [organs, setOrgans] = useState()
    const [organInput, setOrganInput] = useState('')
    const [input, setInput] = useState('')

    let Toast = useSelector((s) => s.toast);

    const history = useHistory();

    useEffect(() => {
        getAllYears().then(res => {
            if (res.success) {
                let py1 = res.data.results.filter(item => item.name === "PY1")
                let py2 = res.data.results.filter(item => item.name === "PY2")

                setYearOne(py1)
                setyearTwo(py2)

            }
        })

    }, [])

    useEffect(() => {
        getAllSubjecOnetWithDuplicates().then(res => {
            if (res.success) {
                let subjectFilterArray = res.data.results.filter(item => {
                    return item.organ.id === organInput
                })
                setSubjectOne(subjectFilterArray)
            }
        })
    }, [organInput])

    useEffect(() => {
        if (yearInput === 1 || yearInput === 2) {
            getAllOrgansWithDuplicates().then(res => {
                if (res.success) {
                    let organFilterArray = res.data.results.filter(item => {
                        return item.year.id === yearInput
                    })
                    setOrgans(organFilterArray)
                }
            })
        }

        if (yearInput === 3 || yearInput === 4) {
            getAllSubjectTwoWithDuplicates().then(res => {
                if (res.success) {
                    let subjectFilterArray = res.data.results.filter(item => {
                        return item.year.id === yearInput
                    })
                    setSubjectTwo(subjectFilterArray)
                }
            })
        }


    }, [yearInput])

    useEffect(() => {
        getAllTopicsWithDuplicates().then(res => {
            if (res.success) {
                let topicFilterArray = res.data.results.filter(item => {
                    return item.subjects.id === subjectInput
                })
                setTopics(topicFilterArray)
            }
        })
    }, [subjectInput])

    useEffect(() => {
        setInput('')
    }, [addData])

    const handleChange = (e) => {
        setInput(e.target.value)
    }

    const handleYearChange = (e) => {
        setYearInput(e.target.value)
    }
    const handleOrganChange = (e) => {
        setOrganInput(e.target.value)
    }

    const handleTopicChange = (e) => {
        setTopicInput(e.target.value)
    }

    const handleSubjectChange = (e) => {
        setSubjectInput(e.target.value)
    }

    const handleSubmit = () => {
        if (addData === 'PY1 Subject') {
            let sendingData = {
                organ_pk: organInput,
                name: input
            }
            createSubjectOne({ ...sendingData }).then(res => {
                if (res.success) {
                    Toast.fire({
                        icon: 'success',
                        title: `Subject created successfully!`
                    })
                    setAddData('')
                    setDeleteData('')
                } else {
                    Toast.fire({
                        icon: 'error',
                        title: `duplicate subject cant be added`
                    })
                }

            })
        }
        if (addData === 'PY2 Subject') {
            let sendingData = {
                year_pk: yearInput,
                name: input
            }
            createSubjectTwo({ ...sendingData }).then(res => {
                if (res.success) {
                    Toast.fire({
                        icon: 'success',
                        title: `Subject created successfully!`
                    })
                    setAddData('')
                    setDeleteData('')
                } else {
                    Toast.fire({
                        icon: 'error',
                        title: `duplicate subject cant be added`
                    })
                }

            })
        }
        if (addData === 'Organ') {
            let sendingData = {
                year_pk: yearInput,
                name: input
            }
            createOrgan({ ...sendingData }).then(res => {
                if (res.success) {
                    Toast.fire({
                        icon: 'success',
                        title: `Organ created successfully!`
                    })
                    setAddData('')
                    setDeleteData('')
                } else {
                    Toast.fire({
                        icon: 'error',
                        title: `duplicate organ cant be added`
                    })
                }
            })
        }
        if (addData === 'Topic') {
            let sendingData = {
                subjects_pk: subjectInput,
                name: input
            }
            createTopic({ ...sendingData }).then(res => {
                if (res.success) {
                    Toast.fire({
                        icon: 'success',
                        title: `Topic created successfully!`
                    })
                    setAddData('')
                    setDeleteData('')
                } else {
                    Toast.fire({
                        icon: 'error',
                        title: `duplicate topic cant be added`
                    })
                }
            })
        }

        setYearInput('')
        setSubjectInput('')
        setAddData('')
        setDeleteData('')
    }

    const handleDelete = () => {
        setAddData('')
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                if (deleteData === 'PY1 Subject') {
                    let sendingData = {
                        id: subjectInput,
                    }
                    deleteSubjectOne(sendingData).then(res => {
                        if (res.status === 204) {
                            Toast.fire({
                                icon: 'success',
                                title: `Subject deleted successfully!`
                            })
                        } else {
                            Toast.fire({
                                icon: 'error',
                                title: `couldn't delete subject`
                            })
                        }
                        setAddData('')
                        setDeleteData('')
                        setOrganInput('')
                        setSubjectInput('')

                    })
                }
                if (deleteData === 'PY2 Subject') {
                    let sendingData = {
                        id: subjectInput,
                    }
                    deleteSubjectTwo(sendingData).then(res => {
                        if (res.status === 204) {
                            Toast.fire({
                                icon: 'success',
                                title: `Subject deleted successfully!`
                            })
                        } else {
                            Toast.fire({
                                icon: 'error',
                                title: `couldn't delete subject`
                            })
                        }
                        setAddData('')
                        setDeleteData('')
                        setYearInput('')
                        setSubjectInput('')

                    })
                }
                if (deleteData === 'Organ') {
                    let sendingData = {
                        id: organInput
                    }
                    deleteOrgan(sendingData).then(res => {
                        if (res.status == 204) {
                            Toast.fire({
                                icon: 'success',
                                title: `Organ deleted successfully!`
                            })
                        } else {
                            Toast.fire({
                                icon: 'error',
                                title: `couldnt delete organ`
                            })
                        }
                        setAddData('')
                        setDeleteData('')
                        setOrganInput('')
                    })
                }
                if (deleteData === 'Topic') {
                    let sendingData = {
                        id: topicInput,
                    }
                    deleteTopic(sendingData).then(res => {
                        if (res.status === 204) {
                            Toast.fire({
                                icon: 'success',
                                title: `Topic deleted successfully!`
                            })
                        } else {
                            Toast.fire({
                                icon: 'error',
                                title: `couldnt delete topic`
                            })
                        }
                    })
                }
            }
        })

        setYearInput('')
        setSubjectInput('')
        setAddData('')
        setDeleteData('')
    }

    const handleBulkUpload = () => {
        window.open(
            process.env.REACT_APP_SERVER_URL_BULK_UPLOAD,
            '_blank'
        )
    }
    return (
        <>
            <Topbar />
            <div className="_master_data_container">
                <Sidebar />
                <div style={{ margin: '20px' }}>
                    <Card sx={{ p: 2, mt: 2 }} elevation={2}>
                        <CardContent>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <h4>Add PY1 Subject</h4>
                                <IconButton
                                    color="primary"
                                    onClick={() => {
                                        setDeleteData('')
                                        setAddData('PY1 Subject')
                                    }}
                                >
                                    <AddCircleIcon />
                                </IconButton>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <h4>Add PY2 Subject</h4>
                                <IconButton
                                    color="primary"
                                    onClick={() => {
                                        setDeleteData('')
                                        setAddData('PY2 Subject')
                                    }}
                                >
                                    <AddCircleIcon />
                                </IconButton>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <h4>Add Organ System</h4>
                                <IconButton
                                    color="primary"
                                    onClick={() => {
                                        setDeleteData('')
                                        setAddData('Organ')
                                    }}
                                >
                                    <AddCircleIcon />
                                </IconButton>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <h4>Add Topic Name</h4>
                                <IconButton
                                    color="primary"
                                    onClick={() => {
                                        setDeleteData('')
                                        setAddData('Topic')
                                    }}
                                >
                                    <AddCircleIcon />
                                </IconButton>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: 20 }}>
                                <Button
                                    sx={{ textTransform: "none" }}
                                    variant="contained" endIcon={<UploadIcon />}
                                    onClick={handleBulkUpload}
                                >
                                    Bulk Upload Data
                                </Button>
                            </div>
                        </CardContent>
                    </Card>
                    {
                        addData ?
                            <Card sx={{ mt: 2 }} className="_master_data_form">
                                <CardContent>
                                    <h4>Add {addData}</h4>
                                    <form action="">
                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                            {
                                                addData === 'Organ' ?
                                                    <YearDropDown
                                                        name="year"
                                                        label="Select year"
                                                        menu={yearOne}
                                                        value={yearInput}
                                                        onChange={handleYearChange}
                                                    />
                                                    : addData === 'Topic' ?
                                                        <>
                                                            <YearDropDown
                                                                name="year"
                                                                label="Select year"
                                                                menu={yearTwo}
                                                                value={yearInput}
                                                                onChange={handleYearChange}
                                                            />
                                                            <DropDown
                                                                name="subject"
                                                                label="Select subject"
                                                                menu={subjectTwo}
                                                                value={subjectInput}
                                                                onChange={handleSubjectChange}
                                                            />
                                                        </>
                                                        : addData === 'PY2 Subject' ?
                                                            <>
                                                                <YearDropDown
                                                                    name="year"
                                                                    label="Select year"
                                                                    menu={yearTwo}
                                                                    value={yearInput}
                                                                    onChange={handleYearChange}
                                                                />
                                                            </>
                                                            : addData === 'PY1 Subject' ?
                                                                <>
                                                                    <YearDropDown
                                                                        name="year"
                                                                        label="Select year"
                                                                        menu={yearOne}
                                                                        value={yearInput}
                                                                        onChange={handleYearChange}
                                                                    />
                                                                    <DropDown
                                                                        name="organ"
                                                                        label="Select organ"
                                                                        menu={organs}
                                                                        value={organInput}
                                                                        onChange={handleOrganChange}
                                                                    />
                                                                </>
                                                                :
                                                                <></>
                                            }
                                            <TextFieldWithIcon
                                                value={input}
                                                label={`Enter ${addData}`}
                                                variant="standard"
                                                onChange={handleChange}
                                            />
                                            <Button
                                                sx={{ mt: 2 }}
                                                variant="contained"
                                                endIcon={<AddIcon />}
                                                onClick={handleSubmit}
                                            >
                                                Add
                                            </Button>
                                        </div>
                                    </form>
                                </CardContent>
                            </Card> : <></>
                    }
                    {
                        deleteData ?
                            <Card sx={{ mt: 2 }} className="_master_data_form">
                                <CardContent>

                                    <h4>Delete {deleteData}</h4>

                                    <form action="">
                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                            {
                                                deleteData === 'Organ' ?
                                                    <>
                                                        <YearDropDown
                                                            name="year"
                                                            label="Select year"
                                                            menu={yearOne}
                                                            value={yearInput}
                                                            onChange={handleYearChange}
                                                        />
                                                        <DropDown
                                                            name="organ"
                                                            label="Select organ"
                                                            menu={organs}
                                                            value={organInput}
                                                            onChange={handleOrganChange}
                                                        />
                                                    </>
                                                    : deleteData === 'Topic' ?
                                                        <>
                                                            <YearDropDown
                                                                name="year"
                                                                label="Select year"
                                                                menu={yearTwo}
                                                                value={yearInput}
                                                                onChange={handleYearChange}
                                                            />
                                                            <DropDown
                                                                name="subject"
                                                                label="Select subject"
                                                                menu={subjectTwo}
                                                                value={subjectInput}
                                                                onChange={handleSubjectChange}
                                                            />
                                                            <DropDown
                                                                name="topic"
                                                                label="Select topic"
                                                                menu={topics}
                                                                value={topicInput}
                                                                onChange={handleTopicChange}
                                                            />
                                                        </>
                                                        : deleteData === 'PY2 Subject' ?
                                                            <>
                                                                <YearDropDown
                                                                    name="year"
                                                                    label="Select year"
                                                                    menu={yearTwo}
                                                                    value={yearInput}
                                                                    onChange={handleYearChange}
                                                                />
                                                                <DropDown
                                                                    name="subject"
                                                                    label="Select subject"
                                                                    menu={subjectTwo}
                                                                    value={subjectInput}
                                                                    onChange={handleSubjectChange}
                                                                />
                                                            </>
                                                            : deleteData === 'PY1 Subject' ?
                                                                <>
                                                                    <YearDropDown
                                                                        name="year"
                                                                        label="Select year"
                                                                        menu={yearOne}
                                                                        value={yearInput}
                                                                        onChange={handleYearChange}
                                                                    />
                                                                    <DropDown
                                                                        name="organ"
                                                                        label="Select organ"
                                                                        menu={organs}
                                                                        value={organInput}
                                                                        onChange={handleOrganChange}
                                                                    />
                                                                    <DropDown
                                                                        name="subject"
                                                                        label="Select subject"
                                                                        menu={subjectOne}
                                                                        value={subjectInput}
                                                                        onChange={handleSubjectChange}
                                                                    />
                                                                </>
                                                                :
                                                                <></>
                                            }
                                            <Button
                                                sx={{ mt: 2 }}
                                                color="warning"
                                                variant="contained"
                                                endIcon={<RemoveIcon />}
                                                onClick={handleDelete}
                                            >
                                                Delete
                                            </Button>
                                        </div>
                                    </form>
                                </CardContent>
                            </Card> : <></>
                    }
                </div>
                <div style={{ margin: '20px' }}>
                    <Card sx={{ p: 2, mt: 2 }} elevation={2}>
                        <CardContent>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <h4>Delete PY1 Subject</h4>
                                <IconButton
                                    color="warning"
                                    onClick={() => {
                                        setAddData('')
                                        setDeleteData('PY1 Subject')
                                    }}
                                >
                                    <RemoveCircleIcon />
                                </IconButton>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <h4>Delete PY2 Subject</h4>
                                <IconButton
                                    color="warning"
                                    onClick={() => {
                                        setAddData('')
                                        setDeleteData('PY2 Subject')
                                    }}
                                >
                                    <RemoveCircleIcon />
                                </IconButton>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <h4>Delete Organ System</h4>
                                <IconButton
                                    color="warning"
                                    onClick={() => {
                                        setAddData('')
                                        setDeleteData('Organ')
                                    }}
                                >
                                    <RemoveCircleIcon />
                                </IconButton>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <h4>Delete Topic Name</h4>
                                <IconButton
                                    color="warning"
                                    onClick={() => {
                                        setAddData('')
                                        setDeleteData('Topic')
                                    }}
                                >
                                    <RemoveCircleIcon />
                                </IconButton>
                            </div>
                        </CardContent>
                    </Card>

                </div>
            </div>
        </>
    )
}
