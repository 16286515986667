import React from "react";
import { Select, MenuItem, InputLabel, FormControl } from '@mui/material';

import './DropDown.scss'


const DropDown = ({ label, menu, value, name, onChange, dependency }) => {

    return (
        <>
            <FormControl variant="standard">
                <InputLabel variant="standard" id="standard-label">{label}</InputLabel>
                <Select
                    labelId="standard-label"
                    id="standard"
                    value={value}
                    name={name}
                    label="standard-label"
                    onChange={onChange}
                    MenuProps={{ PaperProps: { sx: { maxHeight: 300, maxWidth: 400 } } }}
                >
                    {
                        menu &&
                            menu.length > 0 ?
                            menu.map((item, index) => {
                                return <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                            })
                            : <MenuItem key={0} disabled>{dependency}</MenuItem>
                    }

                </Select>
            </FormControl>
        </>
    )
}

export default DropDown;