export const validatePY1LectureCapsule = (inputs) => {
    if (inputs.organ === "") {
        return true
    }
    if (inputs.display_name === "") {
        return true
    }
    if (inputs.keywords === []) {
        return true
    }
    if (inputs.competency_number === []) {
        return true
    }
    if (inputs.links === "") {
        return true
    }
    if (inputs.subject === "") {
        return true
    }

    return false
}

export const validatePY2LectureCapsule = (inputs) => {
    if (inputs.topic_name === "") {
        return true
    }
    if (inputs.display_name === "") {
        return true
    }
    if (inputs.keywords === []) {
        return true
    }
    if (inputs.competency_number === []) {
        return true
    }
    if (inputs.links === "") {
        return true
    }
    if (inputs.subject === "") {
        return true
    }

    return false
}