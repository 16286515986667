import axios from '../index'


export const getToken = (inputs) => {
    return axios.post('/token/', { ...inputs })
        .then(res => res)
        .catch(e => e.response.data)
}

export const getTokenVerified = (data) => {
    return axios.post('/token/verify/', { ...data })
        .then(res => res)
        .catch(e => e.response.data)
}
