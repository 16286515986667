
export const validatePY1InteractiveLearning = (inputs) => {
    if (inputs.organ === "") {
        return true
    }
    if (inputs.display_name === "") {
        return true
    }
    if (inputs.competency_num === []) {
        return true
    }
    if (inputs.keywords === []) {
        return true
    }
    if (inputs.links === "") {
        return true
    }
    if (inputs.subject === "") {
        return true
    }
    return false
}

export const validatePY2InteractiveLearning = (inputs) => {

    if (inputs.topic_name === "") {
        return true
    }
    if (inputs.display_name === "") {
        return true
    }
    if (inputs.keywords === []) {
        return true
    }
    if (inputs.competency_number === []) {
        return true
    }
    if (inputs.links === "") {
        return true
    }
    if (inputs.subject === "") {
        return true
    }

    return false
}

