import React, { useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router'
import { Card, Button } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';

import './Login.scss'
import { signIn } from '../../redux/consts';
import CircularProgress from '@mui/material/CircularProgress';
import { getToken } from '../../services/axios/authAPI/auth'
import TextFieldWithIcon from '../../components/TextFieldWithIcon/TextFieldWithIcon';

export default function Login() {

    const initial = { email: "", password: "" }
    const [inputs, setInputs] = useState(initial);
    const [gotResponse, setGotResponse] = useState(true);

    const history = useHistory();
    const dispatch = useDispatch();

    let Toast = useSelector((s) => s.toast);

    const handleChange = (e) => {
        setInputs({
            ...inputs,
            [e.target.name]: e.target.value
        })
    }

    const handleLogin = async () => {
        setGotResponse(false)
        const response = await getToken(inputs);
        setGotResponse(true)
        if (response.status !== 200) {
            Toast.fire({
                icon: 'error',
                title: `${response.detail}`
            })
            if (response.email || response.password) {
                Toast.fire({
                    icon: 'error',
                    title: `${response.email || response.password}`
                })
            }

        } else {
            if (!response.data.is_staff) {
                Toast.fire({
                    icon: 'error',
                    title: `No admin priviledges!`
                })
            } else {
                let medace_user = {
                    "email": inputs.email,
                    "token": response.data.access
                }
                localStorage.setItem('medace_user', JSON.stringify(medace_user))
                dispatch({
                    type: signIn,
                    payLoad: {
                        initial: {
                            isAuth: true
                        },
                    },
                });
                setGotResponse(true)
                history.push('/lecture-capsule/1');
            }
        }

    }



    return (
        <div className="_login_screen_container">
            <h2>MedAce <span>Admin</span></h2>
            <Card className="_login_card" elevation={4}>
                <p>Sign in to start your session</p>
                <form>
                    <TextFieldWithIcon
                        type="email"
                        name="email"
                        label="Email"
                        value={inputs.email}
                        onChange={handleChange}
                        icon={<EmailIcon />}

                    />
                    <TextFieldWithIcon
                        type="password"
                        name="password"
                        label="Password"
                        value={inputs.password}
                        onChange={handleChange}
                        icon={<LockIcon />}

                    />
                    {
                        gotResponse ?
                            <Button
                                onClick={() => {
                                    handleLogin(inputs);
                                }}
                                variant="contained" sx={{ mt: 3 }}>
                                Sign in
                            </Button>
                            : <div style={{ marginTop: '20px' }}><CircularProgress /></div>
                    }
                </form>
            </Card>
        </div>

    )
}
