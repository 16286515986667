import axios from '../index'
import { headers } from '../headers'

export const getAllUsers = () => {
    return axios.get('/users/', { headers: headers() })
        .then(res => res.data)
        .catch(e => e.response.data)
}

export const createNewUser = (data) => {
    return axios.post('/users/', { ...data }, { headers: headers() })
        .then(res => res.data)
        .catch(e => e.response.data)
}

export const deleteUser = (userId) => {
    return axios.delete(`/users/${userId}/`, { headers: headers() })
        .then(res => res)
        .catch(e => e.response.data)
}

export const getOneUser = (userId) => {
    return axios.get(`/users/${userId}/`, { headers: headers() })
        .then(res => res.data)
        .catch(e => e.response.data)
}

export const updateUser = (userId, data) => {
    return axios.put(`/users/${userId}`, { ...data }, { headers: headers() })
        .then(res => res.data)
        .catch(e => e.response.data)
}