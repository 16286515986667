import React from 'react'
import { Link } from "react-router-dom";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import "./sidebar.scss";
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import QuestionBankIcon from '../../assets/icons/question_bank.png'
import LetureCapsuleIcon from '../../assets/icons/lecture_capsule.png'
import DigitalLearningIcon from '../../assets/icons/digital_learning.png'
import MasterDataIcon from '../../assets/icons/master_data.png'
import ManageUserIcon from '../../assets/icons/user_settings.png'

export default function Sidebar() {

  return (
    <div className="sidebar">
      <div className="sidebarWrapper">
        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Main Menu</h3>
          <ul className="sidebarList">


            {/* ACCORDIAN PY1 */}

            <Accordion defaultExpanded={true}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                PY1
              </AccordionSummary>
              <AccordionDetails>
                <ul className="sidebarList">
                  {/* <Link to="/question-bank/1" className="link">
                    <li className="sidebarListItem">
                      <img src={QuestionBankIcon} alt="question bank icon" className="sidebarIcon" />
                      Question Bank
                    </li>
                  </Link> */}
                  <Link to="/lecture-capsule/1" className="link">
                    <li className="sidebarListItem">
                      <img src={LetureCapsuleIcon} alt="lecture capsule icon" className="sidebarIcon" />
                      Lecture Capsules
                    </li>
                  </Link>
                  <Link to="/digital-learning/1" className="link">
                    <li className="sidebarListItem">
                      <img src={DigitalLearningIcon} alt="digital learning icon" className="sidebarIcon" />

                      Digital Learning
                    </li>
                  </Link>
                </ul>
              </AccordionDetails>
            </Accordion>



            {/* ACCORDIAN PY2  */}
            <Accordion defaultExpanded={true}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                PY2
              </AccordionSummary>
              <AccordionDetails >
                <ul className="sidebarList">
                  {/* <Link to="/question-bank/2" className="link">
                    <li className="sidebarListItem">
                      <img src={QuestionBankIcon} alt="question bank icon" className="sidebarIcon" />

                      Question Bank
                    </li>
                  </Link> */}
                  <Link to="/lecture-capsule/2" className="link">
                    <li className="sidebarListItem">
                      <img src={LetureCapsuleIcon} alt="lecture capsule icon" className="sidebarIcon" />

                      Lecture Capsules
                    </li>
                  </Link>
                  <Link to="/digital-learning/2" className="link">
                    <li className="sidebarListItem">
                      <img src={DigitalLearningIcon} alt="digital learning icon" className="sidebarIcon" />

                      Digital Learning
                    </li>
                  </Link>
                </ul>
              </AccordionDetails>
            </Accordion>

          </ul>

          <h3 className="sidebarTitle" style={{ marginTop: 20 }}>Master Data</h3>

          <Accordion defaultExpanded={true}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              Menu
            </AccordionSummary>
            <AccordionDetails >
              <ul className="sidebarList">
                <Link to="/master" className="link">
                  <li className="sidebarListItem">
                    <img src={MasterDataIcon} alt="master data icon" className="sidebarIcon" />

                    Master Data
                  </li>
                </Link>
                <Link to="/manage-users" className="link">
                  <li className="sidebarListItem">
                    <img src={ManageUserIcon} alt="manage user icon" className="sidebarIcon" />
                    Manage Users
                  </li>
                </Link>
              </ul>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </div>
  );
}
