import * as type from '../consts';

const init = {
    initial: {
        isAuth: false
    },
};

export function signInRed(state = init, action) {
    switch (action.type) {
        case type.signIn:
            return action.payLoad;
        default:
            return state;
    }
}
