import React from 'react'
import { InputAdornment, TextField } from '@mui/material'

export default function TextFieldWithIcon({ type, name, label, value, onChange, icon }) {
    return (
        <TextField
            type={type}
            name={name}
            label={label}
            variant="standard"
            value={value}
            onChange={onChange}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        {icon}
                    </InputAdornment>
                ),
            }}
        />
    )
}
