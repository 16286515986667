import axios from '../index'
import { headers } from '../headers'

export const getAllPY2DigitalLearning = () => {
    return axios.get('/year_2/', {
        params: {
            course_type: 1,
            page_size: 5000
        }
    })
        .then(res => res.data)
        .catch(e => e.response.data)
}


export const getOnePY2DigitalLearning = (id) => {
    return axios.get(`/year_2/${id}`)
        .then(res => res.data)
        .catch(e => e.response.data)
}

export const createPY2DigitalLearning = (data) => {
    return axios.post(`/year_2/`, { ...data, course_type: 1 }, {
        params: {
            course_type: 2,
        },
        headers: headers()
    })
        .then(res => res.data)
        .catch(e => e.response.data)
}

export const updatePY2DigitalLearning = (id, data) => {
    return axios.put(`/year_2/${id}`, { ...data }, { headers: headers() })
        .then(res => res.data)
        .catch(e => e.response.data)
}

export const deletePY2DigitalLearning = (id) => {
    return axios.delete(`/year_2/${id}`, { headers: headers() })
        .then(res => res)
        .catch(e => e.response.data)
}