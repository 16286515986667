import React from "react";
import { Route, Redirect } from "react-router-dom";

const PrivateRoute = (props) => {

    return props.isAllowed ? (<Route
        path={props.path}
        exact={props.exact}
        component={props.component}
    />) :
        (<Redirect to={props.redirect || '/'} />);
};
export default PrivateRoute;